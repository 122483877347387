import { Typography } from "antd";
import { PrimaryButton } from "components/PrimaryButton";
import { CUSTOMER_STATUS, DATA_SOURCE } from "features/PotentialCustomer/constants";
import { useCustomerPermissions } from "hooks/potentialCustomer/query";
import { useSellerPermissions } from "hooks/seller";
import { t } from "i18next";
import ApproveButton from "./ApproveButton";
import CompleteButton from "./CompleteButton";
import ConfirmTakingCareButton from "./ConfirmTakingCareButton";
import { ContactCustomerButton } from "./ContactCustomerButton";
import DesistButton from "./DesistButton";
import RecontactButton from "./RecontactButton";
import RejectButton from "./RejectButton";
import UpdateGroup from "./UpdateGroup";

const ItCustomerButton = ({ onGetValueIsCustomerButton }) => {
  return (
    <PrimaryButton
      onClick={() => onGetValueIsCustomerButton(true)}
      title={
        <Typography.Text style={{ color: "white" }}>{t("potentialCustomer.buttonGroup.isCustomer")}</Typography.Text>
      }
    />
  );
};

function ButtonGroupCustomer({
  data,
  isAgent,
  onGetValueIsCustomerButton,
  isUpdate,
  onSwitchUpdate,
  onSubmitUpdate,
  loadingUpdate,
  handleOkeModalCloseDrawer,
}) {
  const { canCustomerCreate } = useCustomerPermissions();
  const { canSellerAccountApproval } = useSellerPermissions();

  const isCustomerButton =
    canCustomerCreate && [DATA_SOURCE.SELLER_APP, DATA_SOURCE.SELLER_WEB].includes(data?.dataSource);

  switch (data?.status) {
    case CUSTOMER_STATUS.NEW:
      if (isUpdate) {
        return (
          <UpdateGroup
            handleOkeModalCloseDrawer={handleOkeModalCloseDrawer}
            loadingUpdate={loadingUpdate}
            onSubmitUpdate={onSubmitUpdate}
            onSwitchUpdate={onSwitchUpdate}
          />
        );
      }
      return (
        <>
          <DesistButton customerId={data?.id} />
          <ContactCustomerButton chatwootContact={data?.chatwootContact} />
          {isCustomerButton && <ItCustomerButton onGetValueIsCustomerButton={onGetValueIsCustomerButton} />}
          <ConfirmTakingCareButton customerId={data?.id} />
        </>
      );
    case CUSTOMER_STATUS.TAKING_CARE:
      if (isUpdate) {
        return (
          <UpdateGroup
            handleOkeModalCloseDrawer={handleOkeModalCloseDrawer}
            loadingUpdate={loadingUpdate}
            onSubmitUpdate={onSubmitUpdate}
            onSwitchUpdate={onSwitchUpdate}
          />
        );
      }
      return (
        <>
          <DesistButton customerId={data?.id} />
          <ContactCustomerButton chatwootContact={data?.chatwootContact} />
          {isCustomerButton && <ItCustomerButton onGetValueIsCustomerButton={onGetValueIsCustomerButton} />}
          <CompleteButton customerId={data?.id} />
        </>
      );
    case CUSTOMER_STATUS.STOP_CARING:
      return (
        <>
          <ContactCustomerButton chatwootContact={data?.chatwootContact} />
          <RecontactButton customerId={data?.id} />
          {isCustomerButton && <ItCustomerButton onGetValueIsCustomerButton={onGetValueIsCustomerButton} />}
        </>
      );
    case CUSTOMER_STATUS.COMPLETED:
      return (
        <>
          {isAgent && (
            <>
              {canSellerAccountApproval && <RejectButton customerIds={data?.id} />}
              {canSellerAccountApproval && <ApproveButton data={data} />}
            </>
          )}
          <ContactCustomerButton chatwootContact={data?.chatwootContact} />
          {isCustomerButton && !isAgent && <ItCustomerButton onGetValueIsCustomerButton={onGetValueIsCustomerButton} />}
        </>
      );
    default:
      return <div></div>;
  }
}

export default ButtonGroupCustomer;
