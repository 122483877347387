import { ClockCircleOutlined } from "@ant-design/icons";
import { Avatar, List, Skeleton } from "antd";
import { renderCreateAt } from "utils/helperFuncs";
import NotiCard from "../NotiCard";
import styles from "./index.module.scss";

const NotiList = ({ dataSource, pagination, loading, defaultDataLength = 3, onClickCard }) => {
  const AVATAR_PRESET = {
    shape: "square",
    size: 64,
  };

  const defaultData = new Array(defaultDataLength).fill("");

  const handleClickCard = (event, id, notifyObj) => {
    event.stopPropagation();
    onClickCard && onClickCard(id, notifyObj);
  };

  return (
    <div className={`${styles["list-container"]}`}>
      <List
        itemLayout="horizontal"
        dataSource={loading ? defaultData : dataSource}
        split={false}
        pagination={pagination}
        renderItem={(item) => (
          <List.Item onClick={(e) => handleClickCard(e, item?.id, item)}>
            <NotiCard status={item?.status}>
              <div className={styles[`noti-container`]}>
                <Skeleton loading={loading} active avatar={AVATAR_PRESET}>
                  <List.Item.Meta
                    avatar={<Avatar src={item?.avatar} {...AVATAR_PRESET} />}
                    title={<b>{item?.title}</b>}
                    description={
                      <span>
                        <div>{item?.description}</div>
                        <div className={styles[`time`]}>
                          <ClockCircleOutlined />
                          &nbsp;{renderCreateAt(item?.createAt, true)}
                        </div>
                      </span>
                    }
                  />
                </Skeleton>
              </div>
            </NotiCard>
          </List.Item>
        )}
      />
    </div>
  );
};

export default NotiList;
