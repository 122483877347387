import { PlusCircleFilled, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, List, Radio, Row, Tooltip, Typography, Upload } from "antd";
import Img from "assets/images";
import FormInput from "components/FormInput";
import { ACCEPT_IMG_TYPES, FORM_LAYOUT, INPUT_TYPE } from "config/constants";
import CreateSellerGroup from "features/SellerGroup/components/CreateSeller";
import { useUpload } from "hooks/upload";
import { COMPANY_TYPE, PAYMENT_METHOD } from "pages/App/Agent/constants";
import useAgent from "pages/App/Agent/hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { dummyRequest, notify, readFile, validator } from "utils/helperFuncs";
import ButtonGroup from "../ButtonGroup";
import "./index.scss";

//SellCategories note: can custom field later for now it's undefined

const CommonInfo = ({
  isUpdate,
  isApproveSeller,
  form,
  getLogoUrl,
  defaultLogoUrl,
  location,
  setLocation,
  isCompany,
  setIsCompany,
  sellerData,
  saveSeller,
  saveAndAddSeller,
  loadingBtn,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [logo, setLogo] = useState("");
  const [uploading, setUploading] = useState(false);
  const {
    paymentMethodOptions,
    companyOptions,
    citiesOptions,
    districtsOptions,
    wardsOptions,
    warehouseOptions,
    banksOptions,
    handleSearchSelect,
    loadMoreDataCustomers,
    hasMoreData,
    onSearch,
    customerOptions,
    refetch,
  } = useAgent(location);

  const { handleUpload } = useUpload();
  const [isDisabledInput, setIsDisabledInput] = useState(false);
  const [open, setOpen] = useState(false);

  const styleOriginNode = {
    borderTop: "1px solid grey",
    color: "blue",
    padding: "12px",
    textAlign: "center",
    fontWeight: "600",
    cursor: "pointer", // "ant-btn ant-btn-primary btn-create"
  };

  const formFieldsCompanyInfo = [
    {
      formItemOptions: {
        label: t("seller.labelInput.taxIDNumber"),
        name: "taxIDNumber",
        rules: [
          {
            required: true,
            message: t("seller.validateMessage.taxIDNumber"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.taxIDNumber"),
        maxLength: 14,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.businessName"),
        name: "fullName",
        rules: [
          {
            required: true,
            message: t("seller.validateMessage.companyName"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.businessName"),
      },
    },
  ];

  const formFieldsPersonalInfo = [
    {
      formItemOptions: {
        label: t("seller.labelInput.fullName"),
        name: "fullName",
        rules: [{ required: true, message: t("seller.validateMessage.fullName") }],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.fullName"),
        maxLength: 50,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.idNumber"),
        name: "idNumber",
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.idNumber"),
        maxLength: 12,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.taxIDNumber"),
        name: "taxIDNumber",
        rules: [
          {
            required: false,
            message: t("seller.validateMessage.taxIDNumber"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.taxIDNumber"),
        maxLength: 14,
      },
    },
  ];

  const formFieldsCommonInfo = [
    {
      formItemOptions: {
        label: t("seller.labelInput.email"),
        name: "email",
        rules: [
          validator({
            type: "email",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.email"),
        disabled: !!history.location.state,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.telephone"),
        name: "telephone",
        rules: [
          { required: true, message: t("seller.validateMessage.telephone") },
          validator({
            type: "phone",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.telephone"),
        maxLength: 11,
      },
    },
    {
      type: INPUT_TYPE.SELECT_LOAD_MORE,
      formItemOptions: {
        label: t("seller.labelInput.groupCustomer"),
        name: "sellerGroupID",
        rules: [{ required: true, message: t("seller.validateMessage.groupCustomer") }],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.groupCustomer"),
        options: customerOptions,
        dropdownRender: (originNode) => (
          <>
            {originNode}
            <div style={styleOriginNode}>
              <CreateSellerGroup
                formCreateSeller={form}
                refetch={refetch}
                customComponent={
                  <span onClick={() => setOpen(false)}>
                    <PlusCircleFilled />
                    <span style={{ paddingLeft: "12px" }}>{t("customer.addNewFast")}</span>
                  </span>
                }
              />
            </div>
          </>
        ),
        open: open,
        onDropdownVisibleChange: (visible) => setOpen(visible),
      },
      onSearch: onSearch,
      hasMoreData: hasMoreData,
      onLoadMore: loadMoreDataCustomers,
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: t("seller.labelInput.cityID"),
        name: "cityID",
        rules: [{ required: true, message: t("seller.validateMessage.cityID") }],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.cityID"),
        showSearch: true,
        filterOption: (search, option) => handleSearchSelect(search, option),
        options: citiesOptions,
        onChange: handleChangeCity,
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: t("seller.labelInput.districtID"),
        name: "districtID",
        rules: [{ required: true, message: t("seller.validateMessage.districtID") }],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.districtID"),
        showSearch: true,
        filterOption: (search, option) => handleSearchSelect(search, option),
        options: districtsOptions,
        onChange: handleChangeDistrict,
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: t("seller.labelInput.wardID"),
        name: "wardID",
        rules: [{ required: true, message: t("seller.validateMessage.wardID") }],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.wardID"),
        showSearch: true,

        filterOption: (search, option) => handleSearchSelect(search, option),
        options: wardsOptions,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.address"),
        name: "address",
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.address"),
        maxLength: 255,
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: t("seller.labelInput.warehouseIDs"),
        name: "warehouseIDs",
        rules: [{ required: true, message: t("seller.validateMessage.warehouseIDs") }],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.warehouseIDs"),
        options: warehouseOptions,
        mode: "multiple",
      },
    },
  ];

  const formFieldsPayment = [
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: t("seller.labelInput.paymentMethod"),
        name: "paymentMethod",
        rules: [
          {
            required: true,
            message: t("seller.validateMessage.paymentMethod"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.paymentMethod"),
        options: paymentMethodOptions,
      },
    },
    {
      type: INPUT_TYPE.CURRENCY_INPUT,
      formItemOptions: {
        label: t("seller.labelInput.debtLimit"),
        name: "debtLimit",
        rules: [
          {
            required: !isDisabledInput,
            message: t("seller.validateMessage.debtLimit"),
          },
          validator({
            type: "number",
          }),
          validator({
            type: "debt",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.debtLimit"),
        maxLength: 14,
        disabled: isDisabledInput,
        controls: false,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.debtAge"),
        name: "debtAge",
        rules: [
          {
            required: !isDisabledInput,
            message: t("seller.validateMessage.debtAge"),
          },
          validator({
            type: "number",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.debtAge"),
        maxLength: 4,
        disabled: isDisabledInput,
      },
    },
  ];

  const formFieldsContactInfo = [
    {
      formItemOptions: {
        label: t("seller.labelInput.contactName"),
        name: "contactName",
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.contactName"),
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.contactPhone"),
        name: "contactPhone",
        rules: [
          validator({
            type: "phone",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.contactPhone"),
        maxLength: 11,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.contactEmail"),
        name: "contactEmail",
        rules: [
          validator({
            type: "email",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.contactEmail"),
      },
    },
  ];

  const formFieldsVatCompany = [
    {
      formItemOptions: {
        label: t("seller.labelInput.representative"),
        name: "representative",
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.representative"),
      },
    },
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: t("seller.labelInput.bankID"),
        name: "bankID",
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.bankID"),
        options: banksOptions,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.legal_representative_phone"),
        name: "legal_representative_phone",
        rules: [
          validator({
            type: "phone",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.legal_representative_phone"),
        maxLength: 11,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.bankAccountNumber"),
        name: "bankAccountNumber",
        rules: [
          validator({
            type: "number",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.bankAccountNumber"),
        maxLength: 30,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.companyAddress"),
        name: "companyAddress",
        rules: [
          {
            required: true,
            message: t("seller.validateMessage.companyAddress"),
          },
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.companyAddress"),
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.bankBranch"),
        name: "bankBranch",
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.bankBranch"),
        maxLength: 255,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.company_email"),
        name: "company_email",
        rules: [
          validator({
            type: "email",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.company_email"),
      },
    },
  ];

  const formFieldsVatPersonal = [
    {
      type: INPUT_TYPE.SELECT,
      formItemOptions: {
        label: t("seller.labelInput.bankID"),
        name: "bankID",
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.bankID"),
        options: banksOptions,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.bankAccountNumber"),
        name: "bankAccountNumber",
        rules: [
          validator({
            type: "number",
          }),
        ],
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.bankAccountNumber"),
        maxLength: 30,
      },
    },
    {
      formItemOptions: {
        label: t("seller.labelInput.bankBranch"),
        name: "bankBranch",
      },
      inputOptions: {
        placeholder: t("seller.placeholderInput.bankBranch"),
        maxLength: 255,
      },
    },
  ];

  function onUploadChange(info) {
    if (info.file.status === "uploading") {
      setUploading(true);
    }
    if (info.file.status === "done") {
      if (!ACCEPT_IMG_TYPES.includes(info?.file?.type)) {
        notify.error({
          message: t("seller.validateMessage.uploadImage"),
        });
        setUploading(false);
        return;
      }
      setUploading(false);
      readFile({
        getResult: async (result) => {
          const logoUrl = await handleUpload({ file: info?.file?.originFileObj });
          getLogoUrl && getLogoUrl(logoUrl);
          setLogo(result);
        },
        type: "Base64String",
        file: info?.file?.originFileObj,
      });
    } else if (info.file.status === "error") {
      return;
    }
  }

  function handleChangeCity(cityId) {
    form.setFieldsValue({
      districtID: null,
      wardID: null,
    });
    setLocation({
      ...location,
      cityId,
      districtId: null,
    });
  }

  function handleChangeDistrict(districtId) {
    form.setFieldsValue({
      wardID: null,
    });
    setLocation({
      ...location,
      districtId,
    });
  }

  const onFormChange = (_, formData) => {
    const { paymentMethod, type } = formData;
    setIsDisabledInput(paymentMethod === PAYMENT_METHOD.WALLET);
    setIsCompany(type === COMPANY_TYPE.COMPANY);
  };

  useEffect(() => {
    setIsDisabledInput(sellerData?.paymentMethod === PAYMENT_METHOD.WALLET);
  }, [sellerData]);

  const optionVatType = [
    {
      value: true,
      label: t("v2.product.drawer_edit_product.export_vat"),
    },
    {
      value: false,
      label: t("v2.product.drawer_edit_product.not_export_vat"),
    },
  ];

  return (
    <div className="common-info-container">
      <Form form={form} {...FORM_LAYOUT} onValuesChange={onFormChange} initialValues={{ type: COMPANY_TYPE.COMPANY }}>
        <div className="seller-class">
          <label className="seller-class-label">{t("seller.classifySellers")}</label>
          <FormInput
            type={INPUT_TYPE.RADIO_GROUP}
            formItemOptions={{
              name: "type",
            }}
            inputOptions={{
              options: companyOptions,
            }}
          />
        </div>
        <div className="content-frame">
          <div className="common-info">
            <div className="form-content">
              <p className="title">{t("seller.commonInfo")}</p>

              <div className="form">
                <div className="logo">
                  <p className="avatar-title">{t("seller.avatar")}</p>

                  <div className="logo-content">
                    <div className="avatar">
                      <img alt="" src={logo || defaultLogoUrl || Img.AvatarPlaceHolder} />
                    </div>

                    <div className="upload">
                      <p className="note">{t("uploadDocument.uploadImagePlaceholder")}</p>
                      <Upload
                        showUploadList={false}
                        onChange={onUploadChange}
                        customRequest={({ file, onSuccess }) => dummyRequest(file, onSuccess)}
                      >
                        <Button loading={uploading} className="upload-btn">
                          {t("uploadDocument.uploadImage")}
                        </Button>
                      </Upload>
                    </div>
                  </div>
                </div>

                {isCompany
                  ? formFieldsCompanyInfo.concat(formFieldsCommonInfo).map((field, index) => {
                      return <FormInput key={index} {...field} />;
                    })
                  : formFieldsPersonalInfo.concat(formFieldsCommonInfo).map((field, index) => {
                      return <FormInput key={index} {...field} />;
                    })}
              </div>
            </div>
          </div>

          {/* <div className="category-list">
            <div className="form-content">
              <p className="title">{t("seller.inSaleCategory")}</p>
              <div className="form category-list-content">
                <Form.Item name="saleCategories">
                  <SellCategories
                    isCompany={isCompany}
                    isUpdate={isUpdate}
                    seller={sellerData}
                    onChangeCategory={onChangeCategory}
                  />
                </Form.Item>
              </div>
            </div>
          </div> */}

          <div className="bill-info">
            <div className="pay-method">
              <div className="form-content">
                <p className="title">{t("seller.settingPayment")}</p>
                <div className="form pay-method-content">
                  {formFieldsPayment.map((field, index) => {
                    return <FormInput key={index} {...field} />;
                  })}
                </div>
              </div>
            </div>

            {isCompany && (
              <div className="contact-info-company">
                <div className="form-content">
                  <p className="title">{t("seller.contactInfo")}</p>
                  <div className="form contact-info-content">
                    {formFieldsContactInfo.map((field, index) => {
                      return <FormInput key={index} {...field} />;
                    })}
                  </div>
                </div>
              </div>
            )}

            {isCompany && (
              <div className="vat-info-company">
                <div className="form-content">
                  <p className="title">{t("seller.infoVat")}</p>
                  <div className="form vat-info-company-content">
                    <div className="radio-group-type-group">
                      <div>
                        <Typography.Text className="title-label">
                          {t("v2.product.drawer_edit_product.seller_prioritize_export")}
                        </Typography.Text>
                        <Form.Item
                          name="defaultExportVat"
                          rules={[
                            {
                              required: true,
                              message: "Chưa chọn loại đại lý theo VAT",
                            },
                          ]}
                        >
                          <Radio.Group>
                            {optionVatType?.map((item) => {
                              return <Radio.Button value={item?.value}>{item?.label}</Radio.Button>;
                            })}
                          </Radio.Group>
                        </Form.Item>
                      </div>

                      <FormInput
                        type={INPUT_TYPE.CHECK_BOX}
                        formItemOptions={{
                          name: "allowEditVat",
                          valuePropName: "checked",
                        }}
                        inputChildren={
                          <Row className="title-checkbox">
                            <span>Được phép tùy chỉnh VAT &nbsp;</span>
                            <Tooltip
                              title={
                                "Đại lý được phép tùy chỉnh VAT có thể chủ động lựa chọn xuất VAT hoặc không xuất VAT cho những sản phẩm được cài đặt tùy chỉnh VAT"
                              }
                            >
                              <QuestionCircleOutlined />
                            </Tooltip>
                          </Row>
                        }
                      />
                    </div>
                    {formFieldsVatCompany.map((field, index) => {
                      return <FormInput key={index} {...field} />;
                    })}
                  </div>
                </div>
              </div>
            )}

            {!isCompany && (
              <div className="vat-info-personal">
                <div className="form-content">
                  <p className="title">{t("seller.infoVat")}</p>
                  <div className="form vat-info-personal-content">
                    <div className="radio-group-type-group">
                      <div>
                        <Typography.Text className="title-label">
                          {t("v2.product.drawer_edit_product.seller_prioritize_export")}
                        </Typography.Text>
                        <Form.Item
                          name="defaultExportVat"
                          rules={[
                            {
                              required: true,
                              message: "Chưa chọn loại đại lý theo VAT",
                            },
                          ]}
                        >
                          <Radio.Group>
                            {optionVatType?.map((item) => {
                              return <Radio.Button value={item?.value}>{item?.label}</Radio.Button>;
                            })}
                          </Radio.Group>
                        </Form.Item>
                      </div>

                      <FormInput
                        type={INPUT_TYPE.CHECK_BOX}
                        formItemOptions={{
                          name: "allowEditVat",
                          valuePropName: "checked",
                        }}
                        inputChildren={
                          <Row className="title-checkbox">
                            <span>Được phép tùy chỉnh VAT &nbsp;</span>
                            <Tooltip
                              title={
                                "Đại lý được phép tùy chỉnh VAT có thể chủ động lựa chọn xuất VAT hoặc không xuất VAT cho những sản phẩm được cài đặt tùy chỉnh VAT"
                              }
                            >
                              <QuestionCircleOutlined />
                            </Tooltip>
                          </Row>
                        }
                      />
                    </div>
                    {formFieldsVatPersonal.map((field, index) => {
                      return <FormInput key={index} {...field} />;
                    })}
                  </div>
                </div>
              </div>
            )}

            {isCompany ? (
              <div className="login-account-company">
                <div className="field-content">
                  <p className="title">{t("seller.loginAccount")}</p>
                  <div className="form vat-info-personal-content">
                    {sellerData?.users?.length > 0 ? (
                      <List
                        itemLayout="horizontal"
                        dataSource={sellerData?.users || []}
                        renderItem={(user) => (
                          <List.Item style={{ padding: "20px" }}>
                            <Row gutter={[16, 16]}>
                              <Col span={12}>
                                <Typography.Text strong>{t("seller.labelInput.loginAccountName")}: </Typography.Text>
                              </Col>
                              <Col span={12}>
                                <Typography.Text>{user?.fullname || "--"}</Typography.Text>
                              </Col>
                              <Col span={12}>
                                <Typography.Text strong>{t("seller.labelInput.loginAccountEmail")}: </Typography.Text>
                              </Col>
                              <Col span={12}>
                                <Typography.Text>{user?.email || "--"}</Typography.Text>
                              </Col>
                              <Col span={12}>
                                <Typography.Text strong>
                                  {t("seller.labelInput.loginAccountTelephone")}: {}
                                </Typography.Text>
                              </Col>
                              <Col span={12}>
                                <Typography.Text>{user?.telephone || "--"}</Typography.Text>
                              </Col>
                            </Row>
                          </List.Item>
                        )}
                      />
                    ) : (
                      <div className="no-account">
                        <Typography.Text>{t("seller.labelInput.noLoginAccount")}</Typography.Text>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="login-account-personal">
                <div className="field-content">
                  <p className="title">{t("seller.loginAccount")}</p>
                  <div className="form vat-info-personal-content">
                    {sellerData?.users?.length > 0 ? (
                      <List
                        itemLayout="horizontal"
                        dataSource={sellerData?.users || []}
                        renderItem={(user) => (
                          <List.Item style={{ padding: "20px" }}>
                            <Row gutter={[16, 16]} style={{ width: "100%" }}>
                              <Col span={12}>
                                <Typography.Text strong>{t("seller.labelInput.loginAccountName")}: </Typography.Text>
                              </Col>
                              <Col span={12}>
                                <Typography.Text>{user?.fullname || "--"}</Typography.Text>
                              </Col>
                              <Col span={12}>
                                <Typography.Text strong>{t("seller.labelInput.loginAccountEmail")}: </Typography.Text>
                              </Col>
                              <Col span={12}>
                                <Typography.Text>{user?.email || "--"}</Typography.Text>
                              </Col>
                              <Col span={12}>
                                <Typography.Text strong>
                                  {t("seller.labelInput.loginAccountTelephone")}: {}
                                </Typography.Text>
                              </Col>
                              <Col span={12}>
                                <Typography.Text>{user?.telephone || "--"}</Typography.Text>
                              </Col>
                            </Row>
                          </List.Item>
                        )}
                      />
                    ) : (
                      <div className="no-account">
                        <Typography.Text>{t("seller.labelInput.noLoginAccount")}</Typography.Text>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <ButtonGroup
          isUpdate={isUpdate}
          isApproveSeller={isApproveSeller}
          loadingBtn={loadingBtn}
          onSave={saveSeller}
          onSaveAndAdd={saveAndAddSeller}
          onCancel={() => {
            return history.push(isUpdate ? `/seller/detail/${sellerData?.id}` : "/seller");
            // if (history.location.state) {
            //   return history.goBack();
            // }
            // return history.push("/seller");
          }}
        />
      </Form>
    </div>
  );
};

export default CommonInfo;
