import { Col, Row, Space, Typography } from "antd";
import { FontAwesomeIcon, Icon } from "assets/icons";
import CustomModal from "components/CustomModal";
import CustomTable from "components/CustomTable";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { t } from "i18next";
import { formatPadStart } from "utils/helperFuncs";
import TagsList from "../TagsList";
import "./index.scss";
import usePopupShowAllProduct from "./usePopupShowAllProduct";

function PopupShowAllProduct({ data, index, customComponent, title }) {
  const {
    columns,
    productData,
    paginationData,
    handleChangeTable,
    loading,
    onSearch,
    params,
    setParams,
    prodAtt,
    listWarehouse,
    dataManufactures,
    loadingManufactures,
    loadingWarehouses,
    showModal,
    handleCancel,
    visible,
  } = usePopupShowAllProduct({
    data,
    index,
  });

  return (
    <section>
      <CustomModal
        {...{
          width: "85%",
          customComponent: customComponent || (
            <Typography.Link italic underline className="title-show-all">
              {t("order.orderDetail.seeDetails")}({formatPadStart(data?.conditionPromotionProducts?.length)})
            </Typography.Link>
          ),
          footer: false,
          centered: true,
          styleButton: "notButton",
          hideConfirmButton: true,
          hideCancelButton: true,
          title: (
            <Typography.Text strong style={{ fontSize: "22px" }}>
              {title || t("campaign.detailPromo.listProductCampaign")}
            </Typography.Text>
          ),
          visible: visible,
          onOpen: showModal,
          onCancel: handleCancel,
        }}
      >
        <Space direction="vertical" style={{ display: "flex" }}>
          <Row gutter={[16, 8]}>
            <Col span={10} className="search-input">
              <FormInput
                {...{
                  type: INPUT_TYPE,
                  inputOptions: {
                    onChange: onSearch,
                    placeholder: t("campaign.detailPromo.searchCodeAndNameProduct"),
                    prefix: <FontAwesomeIcon icon={Icon.faSearch} style={{ color: "#5B6673" }} />,
                  },
                }}
              />
            </Col>
            <Col span={14}>
              <Typography.Text
                style={{
                  textAlign: "right",
                  display: "block",
                  color: "#052987",
                  lineHeight: "32px",
                }}
              >
                {t("campaign.detailPromo.selected")}: {formatPadStart(data?.conditionPromotionProducts?.length)}
              </Typography.Text>
            </Col>
          </Row>
          <TagsList
            listFilter={params.filters}
            dataManufactures={dataManufactures}
            loadingManufactures={loadingManufactures}
            dataWarehouses={listWarehouse}
            loadingWarehouses={loadingWarehouses}
            dataAttributes={prodAtt}
            params={params}
            setParams={setParams}
          />
          <CustomTable
            loading={loading}
            columns={columns}
            dataSource={productData}
            rowKey={(record) => record?.id}
            pagination={{
              total: paginationData?.total,
              pageSize: paginationData?.limit,
              current: paginationData?.offset / paginationData?.limit + 1 || 1,
              showSizeChanger: false,
            }}
            // scroll={{ x: 2000 }}
            onChange={handleChangeTable}
          />
        </Space>
      </CustomModal>
    </section>
  );
}

export default PopupShowAllProduct;
