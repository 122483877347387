import { Typography } from "antd";
import { SvgIcon } from "assets/icons";
import { FilterLoadMore } from "components/CustomFilterMenu/FilterLoadMore";
import { useGetProduct, useGetProductPriceLog } from "hooks/product/product";
import { useGetStaff } from "hooks/warranty";
import { t } from "i18next";
import { CheckVatType, VAT_TYPE } from "pages/App/Product/constants";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { formatDateTime } from "utils/dateTime";
import { buildQueryString, formatCurrency, getQuery } from "utils/helperFuncs";
import { omit } from "utils/object";
import file_logo from "../../../../assets/icons/file_logo.svg";

const useList = () => {
  const GET_QUERY = getQuery();
  const { id } = useParams();
  const [params, setParams] = useState(() => {
    return {
      filters: {
        query: "",
        orderBy: GET_QUERY?.orderBy || "CREATED_AT_DESC",
        createdAtFilter: {},
        productIDs: [id],
        createdByIDs: GET_QUERY?.createdByIDs || [],
      },
      pagination: {
        offset: GET_QUERY?.offset || 0,
        limit: GET_QUERY?.limit || 10,
      },
    };
  });

  const { data, paginationData, loading } = useGetProductPriceLog(params);
  const { data: dataProductDetail } = useGetProduct({ id });
  const [paramsStaff, setParamsStaff] = useState({
    filters: { query: null },
    pagination: { offset: 0, limit: 10 },
  });
  const { data: staffs, onLoadMoreData, hasMoreData } = useGetStaff(paramsStaff);

  const handleSearchStaffs = (value) => {
    setParamsStaff({
      ...paramsStaff,
      filters: { ...paramsStaff?.filters, query: value },
      pagination: { offset: 0, limit: 10 },
    });
  };

  const checkNullValue = (value) => {
    if (value === null || value === "" || value === undefined) {
      return "- -";
    } else {
      return value;
    }
  };

  const showChangeVat = (type, oldValue, newValue) => {
    let oldValueShowed = showVatValue(oldValue),
      newValueShowed = showVatValue(newValue);

    if (!oldValueShowed && !newValueShowed) {
      return "--";
    }

    switch (type) {
      case t("product.%VAT"):
        return showVatPercent(oldValueShowed, newValueShowed);
      case t("product.differenceVAT"):
        return showVatDiff(oldValueShowed, newValueShowed);
    }
  };

  const showVatValue = (value) => {
    if (!value || value >= 0) {
      return value;
    } else {
      return t("v2.product.drawer_edit_product.not_taxable_shortcut");
    }
  };

  const showVatPercent = (oldValue, newValue) => {
    let oldValueWithPercent = isNaN(oldValue) ? oldValue : `${oldValue} %`,
      newValueWithPercent = isNaN(newValue) ? newValue : `${newValue} %`;

    return checkValueDuplicate(oldValueWithPercent, newValueWithPercent);
  };

  const showVatDiff = (oldValue, newValue) => {
    let oldValueWithCurrency = isNaN(oldValue) ? oldValue : formatCurrency(oldValue),
      newValueWithCurrency = isNaN(newValue) ? newValue : formatCurrency(newValue);

    return checkValueDuplicate(oldValueWithCurrency, newValueWithCurrency);
  };

  const checkValueDuplicate = (oldValue, newValue) => {
    if (oldValue.toString() === newValue.toString()) {
      return oldValue;
    } else
      return (
        <span>
          {checkNullValue(oldValue)}&nbsp;&#10142;&nbsp;
          <span style={{ color: "#32BF46" }}>{checkNullValue(newValue)}</span>
        </span>
      );
  };

  const checkValueDuplicateDiscount = (oldValue, newValue) => {
    if (oldValue === newValue) {
      return oldValue || "--";
    }
    if (oldValue || newValue) {
      return (
        <span style={{ textWrap: "nowrap" }}>
          {oldValue || "--"}&nbsp;&#10142;&nbsp;
          <span style={{ color: "#32BF46" }}>{newValue || "--"}</span>
        </span>
      );
    }
    return "--";
  };

  const handleFilter = (value) => {
    const newParams = {
      ...params,
      filters: {
        ...params.filters,
        ...value,
      },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
      },
    });
  };

  const handleRemoveFilterTag = (key) => {
    setParams({
      pagination: { offset: 0, limit: 10 },
      filters: {
        ...omit({ ...params.filters }, [`${key}`]),
      },
      sort: params.sort,
      activeKey: params.activeKey,
    });
    buildQueryString({
      params: {
        ...omit({ ...params?.filters }, [`${key}`]),
        ...params?.pagination,
        activeKey: params.activeKey,
      },
    });
  };

  const handleSort = (by) => {
    if (params?.filters?.orderBy === "CREATED_AT_ASC") {
      const newParams = {
        ...params,
        filters: { ...params?.filters, orderBy: "CREATED_AT_DESC" },
      };
      setParams(newParams);
    } else {
      const newParams = {
        ...params,
        filters: { ...params?.filters, orderBy: "CREATED_AT_ASC" },
      };
      setParams(newParams);
    }
  };

  const handleDownloadFile = (fileURL) => {
    window.open(fileURL);
  };

  const columns = [
    {
      title: (
        <Typography.Text style={{ color: "#5b6673" }} className="title-table" onClick={() => handleSort("ID")}>
          {t("product.time")}
          &nbsp; &nbsp;
          <SvgIcon.SortIcon className={params?.filters?.orderBy} />
        </Typography.Text>
      ),
      align: "center",
      width: 120,
      render: (_, record, index) => checkNullValue(formatDateTime(record?.createdAt)),
    },
    {
      title: "GIÁ CHƯA VAT",
      align: "right",
      width: 250,
      render: (_, record) => checkValueDuplicate(formatCurrency(record?.oldPrice), formatCurrency(record?.newPrice)),
    },

    {
      title: t("product.EXPORT_VAT"),
      width: 150,
      render: (_, record) =>
        record?.oldVatType === record?.newVatType ? (
          CheckVatType(record?.oldVatType)
        ) : (
          <span style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "8px" }}>
            <span className="icon-old-vat">{CheckVatType(record?.oldVatType)}</span>
            &nbsp;&#10142;&nbsp;
            <span className="icon-new-vat">{CheckVatType(record?.newVatType)}</span>
          </span>
        ),
    },
    {
      title: t("product.differenceVAT"),
      width: 250,
      align: "right",
      render: (_, record) => (
        <div>{showChangeVat(t("product.differenceVAT"), record?.oldVatValue, record?.newVatValue)}</div>
      ),
    },
    {
      title: "LOẠI  % VAT ",
      width: 120,
      render: (_, record) => (
        <div>{showChangeVat(t("product.%VAT"), record?.oldVatPercent, record?.newVatPercent)}</div>
      ),
    },
    {
      title: "GIÁ CÓ VAT",
      align: "right",
      width: 250,
      render: (_, record) => {
        switch (record?.newVatType) {
          case VAT_TYPE.NOT_REQUIRED:
            return record?.newPrice ? "sp không xuất VAT" : "- -";
          case VAT_TYPE.REQUIRED:
          case VAT_TYPE.OPTIONAL:
            return (
              <div>{checkValueDuplicate(formatCurrency(record?.oldPriceVat), formatCurrency(record?.newPriceVat))}</div>
            );
        }
      },
    },
    {
      title: "THÔNG TIN CHÍNH SÁCH GIÁ",
      align: "center",
      children: [
        {
          title: t("product.levelQuantity", { level: "1" }),
          align: "center",
          width: 150,
          render: (_, record) => checkValueDuplicateDiscount(record?.oldLevel1Quantity, record?.newLevel1Quantity),
        },
        {
          title: t("product.levelDiscount", { level: "1" }),
          align: "right",
          width: 250,
          render: (_, record) =>
            checkValueDuplicate(
              record?.oldLevel1Discount ? formatCurrency(record?.oldLevel1Discount) : "--",
              record?.newLevel1Discount ? formatCurrency(record?.newLevel1Discount) : "--"
            ),
        },
        {
          title: t("product.levelQuantity", { level: "2" }),
          align: "center",
          width: 150,
          render: (_, record) => checkValueDuplicateDiscount(record?.oldLevel2Quantity, record?.newLevel2Quantity),
        },
        {
          title: t("product.levelDiscount", { level: "2" }),
          align: "right",
          width: 250,
          render: (_, record) =>
            checkValueDuplicate(
              record?.oldLevel2Discount ? formatCurrency(record?.oldLevel2Discount) : "--",
              record?.newLevel2Discount ? formatCurrency(record?.newLevel2Discount) : "--"
            ),
        },
        {
          title: t("product.levelQuantity", { level: "3" }),
          align: "center",
          width: 150,
          render: (_, record) => checkValueDuplicateDiscount(record?.oldLevel3Quantity, record?.newLevel3Quantity),
        },
        {
          title: t("product.levelDiscount", { level: "3" }),
          align: "right",
          width: 250,
          render: (_, record) =>
            checkValueDuplicate(
              record?.oldLevel3Discount ? formatCurrency(record?.oldLevel3Discount) : "--",
              record?.newLevel3Discount ? formatCurrency(record?.newLevel3Discount) : "--"
            ),
        },
        {
          title: t("product.levelQuantity", { level: "4" }),
          align: "center",
          width: 150,
          render: (_, record) => checkValueDuplicateDiscount(record?.oldLevel4Quantity, record?.newLevel4Quantity),
        },
        {
          title: t("product.levelDiscount", { level: "4" }),
          align: "right",
          width: 250,
          render: (_, record) =>
            checkValueDuplicate(
              record?.oldLevel4Discount ? formatCurrency(record?.oldLevel4Discount) : "--",
              record?.newLevel4Discount ? formatCurrency(record?.newLevel4Discount) : "--"
            ),
        },
      ],
    },
    {
      title: t("product.PEOPLE_CHANGE"),
      width: 200,
      render: (_, record, index) => checkNullValue(record?.createdBy?.fullname),
      filterDropdown: (
        <FilterLoadMore
          data={staffs}
          onLoadMoreData={onLoadMoreData}
          onFilter={(value) => handleFilter({ createdByIDs: value })}
          onSearchTextChange={handleSearchStaffs}
          hasMore={hasMoreData}
        />
      ),
    },
    {
      title: t("product.ATTACHMENT"),
      align: "center",
      fixed: "right",
      width: 100,
      render: (_, record, index) => (
        <Typography.Link>
          <img src={file_logo} alt="" />
          &nbsp;
          <div onClick={() => handleDownloadFile(record?.priceImportSession?.fileURL)}>
            {record?.priceImportSession?.fileName}
          </div>
        </Typography.Link>
      ),
    },
  ];

  const handleChangeTable = (pagination, filters, sorter) => {
    const newParams = {
      ...params,
      pagination: {
        offset: (pagination?.current - 1) * 10,
        limit: 10,
      },
      filters: {
        ...params.filters,
      },
    };
    setParams(newParams);
    buildQueryString({
      params: {
        ...newParams?.filters,
        ...newParams?.pagination,
      },
    });
  };

  return {
    staffs,
    columns,
    data,
    paginationData,
    loading,
    handleChangeTable,
    params,
    dataProductDetail,
    handleRemoveFilterTag,
  };
};

export default useList;
