import { Typography } from "antd";
import { SvgIcon } from "assets/icons";
import FilterMenu from "components/CustomFilterMenu/FilterMenu";
import { useGetManufacturers } from "hooks/manufacturer/manufacturer";
import { useGetProducts } from "hooks/product/product";
import { t } from "i18next";
import { VAT_TYPE } from "pages/App/Product/constants";
import { formatCurrency } from "utils/helperFuncs";
import { arrayUniqueByKey } from "utils/object";
import FilterInStock from "../FilterStock";

const useList = ({ params, onTableChange, onSelected, handleFilter, selectedProduct }) => {
  const { data: products, paginationData, loading } = useGetProducts(params);
  const { dataManufactures: manufacturersOptions } = useGetManufacturers();
  const vatOptions = [
    { label: t("v2.product.drawer_edit_product.export_vat"), value: VAT_TYPE.REQUIRED },
    { label: t("v2.product.drawer_edit_product.not_export_vat"), value: VAT_TYPE.NOT_REQUIRED },
    { label: t("v2.product.drawer_edit_product.optional"), value: VAT_TYPE.OPTIONAL },
  ];

  const onChange = (pagination) => {
    onTableChange && onTableChange(pagination);
  };

  const onFilter = (key, value) => {
    if (key === "realStocks") {
      const realStocksFilter = arrayUniqueByKey(
        [...(params?.filters?.realStocks ? params?.filters?.realStocks : []), value],
        "warehouseID"
      ).filter((item) => item?.range?.from || item.range?.to);

      handleFilter && handleFilter(key, realStocksFilter);
    } else {
      handleFilter && handleFilter(key, value);
    }
  };

  const columns = [
    {
      title: t("product.tableTitle.code"),
      render: (text, record, index) => <Typography.Link>{record?.code}</Typography.Link>,
    },
    {
      title: t("product.tableTitle.name"),
      render: (text, record, index) => <Typography.Text>{record?.name}</Typography.Text>,
    },
    {
      title: t("product.tableTitle.producer"),
      render: (text, record, index) => <Typography.Text>{record?.manufacturer?.name}</Typography.Text>,
      ...(!selectedProduct?.isFilterSelected && {
        filterDropdown: (
          <FilterMenu
            onFilter={(value) => onFilter("manufacturerIDs", value)}
            type="checkbox"
            options={manufacturersOptions}
          />
        ),
      }),
    },
    {
      title: t("product.tableTitle.price"),
      align: "end",
      render: (text, record, index) => (
        <Typography.Text>{formatCurrency(record?.priceOfSeller?.price)}</Typography.Text>
      ),
    },
    {
      title: t("product.tableTitle.inStock"),
      align: "center",
      render: (text, record, index) => (
        <Typography.Text>{record?.stocks ? record?.stocks[0]?.quantity : ""}</Typography.Text>
      ),
      ...(!selectedProduct?.isFilterSelected && {
        filterDropdown: <FilterInStock warehouseIDs={params?.warehouseIDs} onFilter={onFilter} />,
      }),
    },
    {
      title: t("product.tableTitle.exportedVAT"),
      align: "center",
      render: (text, record, index) => {
        switch (record?.vatType) {
          case VAT_TYPE.REQUIRED:
            return <SvgIcon.SuccessIcon />;
          case VAT_TYPE.NOT_REQUIRED:
            return <SvgIcon.CloseIcon />;
          case VAT_TYPE.OPTIONAL:
            return <span style={{ color: "#052987" }}>{t("v2.product.drawer_edit_product.optional")}</span>;
        }
      },
      ...(!selectedProduct?.isFilterSelected && {
        filterDropdown: (
          <FilterMenu onFilter={(value) => onFilter("vatType", value)} type="radio" options={vatOptions} />
        ),
      }),
    },
  ];

  const onSelectedRows = (selectedRowKeys, selectedRows) => {
    onSelected && onSelected(selectedRowKeys, selectedRows);
  };

  return { columns, products, paginationData, onChange, loading, onSelectedRows };
};

export default useList;
