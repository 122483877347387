/* eslint-disable react-hooks/exhaustive-deps */
import { CameraOutlined, CopyOutlined } from "@ant-design/icons";
import { Button, Col, Form, Radio, Row, Space, Typography, message } from "antd";
import CustomModal from "components/CustomModal";
import CustomTable from "components/CustomTable";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import { PrimaryButton } from "components/PrimaryButton";
import copy from "copy-to-clipboard";
import * as htmlToImage from "html-to-image";
import { t } from "i18next";
import { VAT_TYPE } from "pages/App/Product/constants";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "utils/helperFuncs";

const QUOTATION_FORM = {
  LINK: "LINK",
  INFO: "INFO",
};

function ModalRecentlyDiscountedProduct({
  data,
  paginationData,
  productDataNoLimit,
  loading,
  quotationForm,
  handleGetQuotationForm,
  handleCopyLink,
  loadingGenLink,
  handleGetProductNoLimit,
}) {
  const [form] = Form.useForm();

  const [dataSource, setDataSource] = useState();
  const [dataNoLimit, setDataNoLimit] = useState();
  const [visible, setVisible] = useState(false);

  const switchData = productDataNoLimit;

  const initialValues = switchData.map((item) => ({
    id: item?.id,
    code: item?.code,
    name: item?.name,
    price: item?.productPrices?.price,
    priceVat: item?.productPrices?.priceVat,
    oldPrice: item?.lastPriceLog?.oldPrice,
    newPrice: item?.lastPriceLog?.newPrice,
    oldPriceVat: item?.lastPriceLog?.oldPriceVat,
    newPriceVat: item?.lastPriceLog?.newPriceVat,
    vatType: item?.vatType,
  }));

  const checkNullValue = (value) => {
    if (value === null || value === "" || value === undefined) {
      return "- -";
    } else {
      return value;
    }
  };

  const checkValueDuplicate = (oldValue, newValue) => {
    if (oldValue.toString() === newValue.toString()) {
      return oldValue;
    } else
      return (
        <span>
          <div>{checkNullValue(oldValue)}</div>
          <div style={{ color: "#32BF46" }}>&nbsp;&#10142;&nbsp;{checkNullValue(newValue)}</div>
        </span>
      );
  };

  const columns = [
    {
      title: (
        <Typography.Text style={{ color: "rgba(91, 102, 115, 1)" }} strong>
          STT
        </Typography.Text>
      ),
      align: "center",
      width: 50,
      render: (_, __, index) => index + 1,
    },
    {
      title: "THÔNG TIN SẢN PHẨM",
      width: 300,
      render: (_, record) => (
        <>
          <Link to={`/product/detail/${record?.id}`} target="_blank">
            <Typography.Link strong>{record?.code}</Typography.Link>
          </Link>
          <CustomTextHiddenLine text={record?.name} line={3} />
        </>
      ),
    },

    {
      title: "GIÁ CHƯA VAT",
      width: 150,
      align: "right",
      render: (_, record) => {
        return checkValueDuplicate(formatCurrency(record?.oldPrice), formatCurrency(record?.newPrice));
      },
    },
    {
      title: "GIÁ CÓ VAT",
      width: 150,
      align: "right",
      render: (_, record) => {
        switch (record?.vatType) {
          case VAT_TYPE.NOT_REQUIRED:
            return record?.price ? "sp không xuất VAT" : "- -";
          case VAT_TYPE.REQUIRED:
          case VAT_TYPE.OPTIONAL:
            return (
              <div>{checkValueDuplicate(formatCurrency(record?.oldPriceVat), formatCurrency(record?.newPriceVat))}</div>
            );
        }
      },
    },
  ];

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const formattedData = dataSource?.map(({ name, price, priceVat }) => {
    const rowData = [name];

    rowData.push(formatCurrency(price));

    rowData.push(formatCurrency(priceVat));

    return rowData;
  });

  const textToCopy = formattedData?.map((row) => row.join("\t")).join("\n");

  const handleCopyTable = () => {
    copy(textToCopy);
    message.success(t("product.copyTypeTextSuccess"));
  };

  const tableRef = useRef(null);
  const [loadingCopy, setLoadingCopy] = useState(false);

  const copyTableToClipboard = async () => {
    setLoadingCopy(true);
    try {
      const table = tableRef.current;
      await htmlToImage
        .toPng(table)
        .then((dataUrl) => {
          const blob = dataURLToBlob(dataUrl);
          // eslint-disable-next-line no-undef
          const item = new ClipboardItem({ [blob.type]: blob });
          navigator.clipboard
            .write([item])
            .then(() => {
              setLoadingCopy(false);
              message.success(t("product.copyTypeImageSuccess"));
            })
            .catch((error) => {
              console.error("Failed to copy table as image to clipboard:", error);
            });
        })
        .catch((error) => {
          console.error("Conversion failed:", error);
          setLoadingCopy(false);
        });
    } catch (error) {
      console.info("Conversion failed:", error);
    }
  };

  const dataURLToBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    const n = bstr.length;
    const u8arr = new Uint8Array(n);
    for (let i = 0; i < n; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new Blob([u8arr], { type: mime });
  };

  useEffect(() => {
    setDataSource([...initialValues]);
    setDataNoLimit([...initialValues]);
    form.setFieldsValue({ ...initialValues });
  }, [data, productDataNoLimit, quotationForm]);

  return (
    <CustomModal
      customComponent={
        <Typography.Text
          underline
          style={{ color: "rgba(156, 173, 195, 1)", fontSize: "13px", cursor: "pointer" }}
          onClick={handleGetProductNoLimit}
        >
          Chi tiết
        </Typography.Text>
      }
      title={<Typography.Title level={4}>{t("product.infoProducPrice")}</Typography.Title>}
      closable={false}
      centered
      footer={false}
      width={"55%"}
      hideConfirmButton
      onOpen={openModal}
      onCancel={closeModal}
      visible={visible}
      addMoreButton={
        quotationForm === QUOTATION_FORM.LINK ? (
          <PrimaryButton title={"Sao chép"} onClick={handleCopyLink} loading={loadingGenLink} />
        ) : (
          <Space>
            <Button onClick={copyTableToClipboard} loading={loadingCopy} icon={<CameraOutlined />} type="primary">
              {t("product.copyTypeImage")}
            </Button>
            <Button onClick={handleCopyTable} icon={<CopyOutlined />} type="primary">
              {t("product.copyTypeText")}
            </Button>
          </Space>
        )
      }
    >
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <Row>
            <Col span={5}>
              <Typography>
                <Typography.Text strong>Hình thức báo giá </Typography.Text>
                <span style={{ color: "red" }}>*</span>
              </Typography>
            </Col>
            <Col>
              <Radio.Group
                defaultValue={quotationForm}
                value={quotationForm}
                onChange={(e) => handleGetQuotationForm(e.target.value)}
              >
                <Radio value={QUOTATION_FORM.LINK}>Sao chép link báo giá</Radio>
                <Radio value={QUOTATION_FORM.INFO}>Sao chép thông tin báo giá</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
        {quotationForm === QUOTATION_FORM.LINK && (
          <Col span={24}>
            <Typography.Text type="warning">Lưu ý: Link sao chép sẽ điều hướng đại lý mở app.</Typography.Text>
          </Col>
        )}

        <Col span={24}>
          <Form form={form} initialValues={initialValues} style={{ paddingBottom: "16px" }}>
            <div ref={tableRef}>
              {quotationForm === QUOTATION_FORM.LINK ? (
                <CustomTable
                  rowKey={(record) => record?.id}
                  bordered
                  columns={columns}
                  loading={loading}
                  dataSource={dataNoLimit}
                  {...(paginationData?.total > 10 && {
                    scroll: {
                      x: "max-content",
                      y: "500px",
                    },
                  })}
                />
              ) : (
                <CustomTable
                  rowKey={(record) => record?.id}
                  bordered
                  columns={columns}
                  loading={loading}
                  dataSource={dataNoLimit}
                />
              )}
            </div>
          </Form>
        </Col>
      </Row>
    </CustomModal>
  );
}

export default ModalRecentlyDiscountedProduct;
