/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Typography } from "antd";
import CustomModal from "components/CustomModal";
import CustomTable from "components/CustomTable";
import { CHATWOOT_URL } from "config/platform";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { notify } from "utils/helperFuncs";

export const DirectContact = ({ listUsers, createdByID, saleChannel }) => {
  const [isModal, setIsModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const handleOpen = () => {
    const hasActive = listUsers?.some((item) => item.status === "ACTIVE");
    if (hasActive) {
      setIsModal(true);
    } else {
      notify.warning({
        message: t("v2.seller.add_contact_button.message_warning_seller_not_user"),
      });
    }
  };

  const handleCancel = () => {
    setIsModal(false);
    setLoading(false);
    setShowWarning(false);
    setSelectedRows([]);
    setSelectedRowKeys([]);
  };

  const handleSubmit = async () => {
    try {
      if (selectedRows.length === 0) {
        setShowWarning(true);
      } else {
        const chatwootContact = selectedRows.length > 0 && selectedRows[0].chatwootContact;
        if (chatwootContact?.url) {
          window.open(CHATWOOT_URL + "/" + chatwootContact?.url, "_blank");
          handleCancel();
        } else {
          notify.warning({
            message: t("v2.seller.add_contact_button.message_warning_seller_not_user"),
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      title: t("v2.seller.add_contact_button.login_account"),
      render: (text, record) => {
        return <Typography>{record?.email ?? "- -"}</Typography>;
      },
    },
    {
      title: t("v2.seller.add_contact_button.username"),
      align: "left",
      width: 350,
      render: (text, record) => {
        return <Typography>{record?.fullname ?? "- -"}</Typography>;
      },
    },
    {
      title: t("v2.seller.add_contact_button.telephone"),
      align: "center",
      render: (text, record) => {
        return <Typography>{record?.telephone ?? "- -"}</Typography>;
      },
    },
    {
      title: t("v2.seller.add_contact_button.active_status"),
      align: "center",
      render: (text, record, index) => {
        return (
          <Typography>
            {record?.status === "ACTIVE"
              ? t("v2.seller.add_contact_button.active")
              : t("v2.seller.add_contact_button.un_active")}
          </Typography>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setShowWarning(false);
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.status !== "ACTIVE",
      name: record.name,
    }),
  };

  useEffect(() => {
    const listSingleActive = listUsers?.filter((item) => item.status === "ACTIVE");
    if (listUsers?.length === 1 && listUsers[0]?.status === "ACTIVE") {
      setSelectedRowKeys([listUsers[0]?.id]);
      setSelectedRows([listUsers[0]]);
    }

    if (listSingleActive?.length === 1) {
      setSelectedRowKeys([listSingleActive[0]?.id]);
      setSelectedRows([listSingleActive[0]]);
    }

    if (listUsers?.length > 1) {
      const defaultUser = listUsers?.find((element) => element.id === createdByID);
      if (createdByID && saleChannel?.type === "APP" && defaultUser?.status === "ACTIVE") {
        setSelectedRowKeys([createdByID]);
        setSelectedRows([defaultUser]);
      }
    }
  }, [isModal]);

  return (
    <CustomModal
      visible={isModal}
      onCancel={handleCancel}
      onOpen={handleOpen}
      onOke={handleSubmit}
      closable={false}
      footer={false}
      title={
        <Typography.Text style={{ fontSize: "20px" }} strong>
          Chọn tài khoản liên hệ qua Chatwoot
        </Typography.Text>
      }
      customComponent={<Typography.Text>Nhắn tin 1-1</Typography.Text>}
      width={1000}
      buttonLoading={loading}
      okeButtonLabel={"Liên hệ"}
      message={
        <Col span={24}>
          <Row>
            <Typography.Text strong>Hình thức liên hệ:&nbsp;</Typography.Text>
            <Typography.Text>Nhắn tin 1-1</Typography.Text>
          </Row>

          <Row style={{ marginBottom: "8px" }}>
            <Typography.Text style={{ color: "#EF9351" }}>
              Vui lòng lựa chọn 01 tài khoản có trạng thái là Đang hoạt động
            </Typography.Text>
          </Row>

          <CustomTable
            size="large"
            rowSelection={{
              type: "radio",
              ...rowSelection,
            }}
            rowKey={(record) => record?.id}
            columns={columns}
            dataSource={listUsers}
          />
          {showWarning && (
            <Row>
              <Typography.Text style={{ color: "#D2434D" }}>
                Vui lòng chọn 01 tài khoản để liên hệ qua Chatwoot
              </Typography.Text>
            </Row>
          )}
        </Col>
      }
    />
  );
};
