import { PrimaryButtonOutLine } from "components/PrimaryButton";
import { CHATWOOT_URL } from "config/platform";
import { t } from "i18next";
import { notify } from "utils/helperFuncs";

export const ContactCustomerButton = ({ chatwootContact }) => {
  const handleOpenLinkContactCustomer = () => {
    if (chatwootContact?.url) {
      window.open(CHATWOOT_URL + "/" + chatwootContact?.url, "_blank");
    } else {
      notify.warning({
        message: t("v2.customer.add_contact_button.message_warning_customer_not_user"),
      });
    }
  };
  return (
    <PrimaryButtonOutLine
      onClick={() => handleOpenLinkContactCustomer()}
      title={t("v2.customer.add_contact_button.contact_customer")}
    />
  );
};
