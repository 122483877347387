import { Button, Dropdown, Menu, Popover, Space, Tooltip, Typography } from "antd";
import { SvgIcon } from "assets/icons";
import { CHATWOOT_URL } from "config/platform";
import { DirectContact } from "features/Agent/components/DirectContact";
import { EXPORT_ORDER_TYPE } from "features/Order/constants";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import { t } from "i18next";
import { notify } from "utils/helperFuncs";
import "./index.scss";

const Toolbar = ({ onExportOrder, onPrintOrder, dataToolBar }) => {
  const [_, copy] = useCopyToClipboard();
  const { shareLink, seller, createdByID, saleChannel } = dataToolBar;

  const handleOpenLinkContactCustomer = () => {
    if (seller?.users && seller?.users[0]?.chatwootContact?.url) {
      window.open(CHATWOOT_URL + "/" + seller?.users[0]?.chatwootContact?.url, "_blank");
    } else {
      notify.warning({
        message: t("v2.customer.add_contact_button.message_warning_customer_not_user"),
      });
    }
  };

  const handleOpenLinkContactSeller = () => {
    const hasActive = seller?.users?.some((item) => item.status === "ACTIVE");

    if (hasActive && seller?.chatwootContact?.url) {
      window.open(CHATWOOT_URL + "/" + seller?.chatwootContact?.url, "_blank");
    } else {
      notify.warning({
        message: t("v2.seller.add_contact_button.message_warning_seller_not_user"),
      });
    }
  };

  const menusContact = (
    <Menu>
      <Menu.Item>
        <DirectContact listUsers={seller?.users} saleChannel={saleChannel} createdByID={createdByID} />
      </Menu.Item>
      <Menu.Item onClick={() => handleOpenLinkContactSeller()}>
        <Typography.Text>Nhắn tin nhóm</Typography.Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <Space className="order-toolbar">
      <Space>
        <Popover
          content={
            <Space direction="vertical">
              <Button type="text" onClick={() => onExportOrder(EXPORT_ORDER_TYPE.JPEG)}>
                {t("common.image")}
              </Button>
              <Button type="text" onClick={() => onExportOrder("XLSX")}>
                {t("common.excel")}
              </Button>
            </Space>
          }
        >
          <Button type="text" className="button">
            <SvgIcon.ExportFileCartIcon />
            <span>{t("cart.export")}</span>
          </Button>
        </Popover>

        <Button type="text" className="button" onClick={onPrintOrder}>
          <SvgIcon.PrinterIcon />
          <span>{t("order.orderDetail.printOrder")}</span>
        </Button>

        <Tooltip title={t("order.shareOrder")} trigger={"click"} placement="bottom">
          <Button type="text" className="button" onClick={() => copy(shareLink)}>
            <SvgIcon.ShareIcon />
            <span>{t("common.share")}</span>
          </Button>
        </Tooltip>
      </Space>

      <Space>
        {saleChannel?.type !== "WEB" &&
          (seller?.approvalStatus === "APPROVED" && seller?.isActive ? (
            <Dropdown overlay={menusContact}>
              <Button type="text" className="button">
                <SvgIcon.IconLetter />
                <span>Liên hệ đại lý</span>
              </Button>
            </Dropdown>
          ) : (
            <Button type="text" className="button" onClick={() => handleOpenLinkContactCustomer()}>
              <SvgIcon.IconLetter />
              <span>Liên hệ khách hàng</span>
            </Button>
          ))}

        <Button type="text" className="button">
          <SvgIcon.HelpCircleIcon />
          <span>{t("cart.help")}</span>
        </Button>
      </Space>
    </Space>
  );
};

export default Toolbar;
